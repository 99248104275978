import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Home from '@interco/icons/build-v4/orangeds/MD/home'
import { Link } from 'gatsby'
import InvestmentsTab from 'src/components/InvestmentsTab'
import FAQ from 'src/components/StructuredData/FAQ'
import BlogArticles from 'src/components/BlogArticles'
import Img from 'gatsby-image'
import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import criJSON from './cri.json'

// JSON
import pageContext from './pageContext.json'
import usePageQuery from './pageQuery'
import { FAQSection, Section } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'
const { qrCodeContaKidsAberturaDeContasInvestimentos, qrCodeAberturaDeContaCri } = qrcodeInvestimentsData

function CRI () {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isModalOpen, setIsModalOpen ] = React.useState(false)
  const domReady = useDomReady()

  const cardsModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        urlContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.deeplink}
        qrCodeContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.qrcode}
        closeModal={() => setIsModalOpen(false)}
        qrBaixeApp={qrCodeAberturaDeContaCri.qrcode}
        asFilledLink={qrCodeAberturaDeContaCri.deeplink}
      />
    </Modal>
  )

  const dataLayer: IDataLayerParams = {
    section: 'dobra_05',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

  return (
    <Layout pageContext={pageContext}>
      {cardsModal}
      <Section>
        <div className='container py-5'>
          <div className='row align-items-md-center'>
            <div className='col-12 col-md-6 col-lg-5 col-xl-6 offset-lg-1 offset-xl-0 order-md-last'>
              <Img fluid={data.imgHero.fluid} alt='Tela do Super App da Inter Invest mostrando como investir em CRI.' />
            </div>
            <div className='col-12 col-md-6 col-lg-6 col-xl-6 pr-md-0'>
              <div className='d-md-block text-grayscale--500 mb-lg-2'>
                <div className='bread'>
                  <Link
                    to='/'
                    className='d-md-inline'
                  ><Home height={24} width={24} color='#6A6E81' />
                  </Link>
                  <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
                  <Link
                    to='/pra-voce/investimentos/'
                    className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
                  >
                    {criJSON.breadcrumb[0]}
                  </Link>
                  <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
                  <Link
                    to='/pra-voce/investimentos/renda-fixa/'
                    className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2 ml-2'
                  >
                    {criJSON.breadcrumb[1]}
                  </Link>
                  <OrangeIcon icon='arrow-right' color='#161616' size='SM' className='ml-2 ' />
                  <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{criJSON.breadcrumb[2]}</p>
                </div>
              </div>
              <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 mb-3 text-grayscale--500 mt-3 mt-lg-0 fw-600'>
                CRI: investimento <span className='d-lg-block'>imobiliário, seguro </span>e rentável
              </h1>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-4 pb-2'>
                Investir em CRI é seguro, pois oferece baixos riscos, <span className='d-lg-block'>é rentável e, ainda, isento de Imposto de Renda.</span>
              </p>
              <div>
                <button
                  className='btn btn--orange btn--lg rounded-3 mw-100'
                  onClick={() => {
                    setIsModalOpen(true)
                  }}
                >
                  Conheça
                </button>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <section id='outros-investimentos'>
        <div className='container pt-5 py-md-5'>
          <h2 className='fs-28 fs-md-42 text-md-center mt-2'>Veja outros tipos de investimentos</h2>
          <InvestmentsTab
            section='dobra_2'
            elementAction='click button'
            section_name='Veja outros tipos de investimentos'
          />
        </div>
      </section>

      <section id='novidades-investimentos' className='py-md-4'>
        <div className='container'>
          <div className='col-12 mb-4'>
            <h2 className='fs-28 mb-0 fs-lg-42 text-left text-lg-center'>Os melhores conteúdos sobre investimentos</h2>
            <p className='text-left mt-1 text-lg-center mb-0'>
              Esteja atualizado com as novidades do blog e invista com segurança aumentando os seus resultados.
            </p>
          </div>
          <BlogArticles
            type='investments'
            section='dobra_3'
            elementAction='click button'
            elementName='Saiba mais'
            sectionName='Confira algumas dicas para investir melhor'
          />
        </div>
      </section>

      <FAQSection id='perguntas-frequentes' className='bg-gray pt-5 pb-4 pb-md-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb-3'>
              <h2 className='fs-28 fs-md-42 text-md-center'>Perguntas frequentes</h2>
              <p className='fs-16 text-md-center'>
                <span className='d-lg-block'>Ainda tem dúvidas? Veja se podemos te ajudar respondendo algumas</span> perguntas ou entre em contato com a gente.
              </p>
            </div>
            <FAQ search={false} id='investimentos-debentures' styles='summary-content px-0' data={pageContext.structuredData.faq} />
          </div>
        </div>
      </FAQSection>
      <DisclaimerInterInvest
        sendDatalayerEvent={sendDatalayerEvent}
        dataLayer={dataLayer}
        section='dobra_05'
      />
    </Layout>
  )
}

export default CRI
